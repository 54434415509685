<template>
  <!-- <WrapBox> -->
  <div class="eightPage" ref="qudao" v-show="qudaoShow">
    <div class="bigTitle bold animate__animated animate__fadeInUp">
      {{ $t("申请步骤介绍") }}
    </div>
    <div class="smallTitle regular animate__animated animate__zoomInDown">
      <span class="animate__animated animate__zoomInDown">
        {{
          $t("您仅需享受最快10分钟申请 立即拥有输你的自己的SmartCard")
        }} </span
      ><br />
      <span class="animate__animated animate__zoomInDown">
        {{ $t("申请前务必核对申请条件及基础信息!") }}
      </span>
    </div>
    <div class="content">
      <div class="bg"></div>
      <div
        class="colorBg animate__animated animate__zoomInRight animate__delay-1s"
      >
        <div class="imgs">
          <img
            src="@/assets/imgs/step1.png"
            alt=""
            class="box animate__animated animate__zoomInRight animate__delay-2s animate__faster"
          />
          <img
            src="@/assets/imgs/step2.png"
            alt=""
            class="box animate__animated animate__zoomInRight animate__delay-3s animate__faster"
          />
          <img
            src="@/assets/imgs/step3.png"
            alt=""
            class="box animate__animated animate__zoomInRight animate__delay-4s animate__faster"
          />
        </div>
      </div>
    </div>
    <div
      class="content1"
      :style="
        currentLanguage == 'zh'
          ? 'height: 80px'
          : currentLanguage == 'en'
          ? 'height: 170px'
          : 'height: 100px'
      "
    >
      <div
        class="stepText1 animate__animated animate__zoomInRight animate__delay-2s animate__faster"
      >
        {{ $t("在线会员申请，注册1分钟") }}<br />
        {{ $t("完成会员申请后开，开卡填写资料最长5分钟") }}
      </div>
      <div
        class="stepText2 animate__animated animate__zoomInRight animate__delay-3s animate__faster"
      >
        {{ $t("将USDT转入钱包，增加自己的数字资产") }}<br />{{
          $t("（请根据需求，量力而行）")
        }}
      </div>
      <div
        class="stepText3 animate__animated animate__zoomInRight animate__delay-4s animate__faster"
      >
        {{ $t("申请开卡，选择您希望的品牌卡片（visa") }}<br />{{
          $t("或master），将USDT转入卡内转变为消")
        }}
      </div>
    </div>
    <el-button type="primary" class="createCard demibold" @click="toLogin">{{
      $t("立即开卡")
    }}</el-button>
  </div>
  <!-- </WrapBox> -->
</template>
<script>
// import WrapBox from "./wrap/wrapBox.vue";
export default {
  name: "eightPage",
  //   components: { WrapBox },
  data() {
    return {
      qudaoShow: false,
      isAnimating: false,
      currentLanguage: "zh",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.currentLanguage = localStorage.getItem("accept_language");
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var b, c, a, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        // e = dom.offsetHeight; // 元素高度
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b + c > a + e) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
    toLogin() {
      window.open("https://merchant.insmartpay.com/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.eightPage {
  height: 100%;
  background-color: #131313;
  color: #fff;
  padding: 0 0 20px;
  .bigTitle {
    font-size: 20px;
    font-weight: 900;
    line-height: 26.52px;
    text-align: center;
    margin-bottom: 20px;
  }
  .smallTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.91px;
    text-align: center;
    color: #fff;
    opacity: 0.8;
  }
  .content {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    margin-bottom: 15px;
    .bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 36.59px;
      background-color: #353535;
      animation: move 2s forwards;
    }
    .colorBg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 33px;
      opacity: 0.8px;
      background: linear-gradient(181deg, #846d37 8.32%, #624d21 96.16%);
      img {
        display: inline-block;
        width: 116px;
        height: 74px;
        position: absolute;
        z-index: 111;
        &:nth-child(1) {
          left: 5%;
          top: -30px;
        }
        &:nth-child(2) {
          left: 50%;
          transform: translateX(-50%);
          top: -30px;
        }
        &:nth-child(3) {
          right: 5%;
          top: -30px;
        }
      }
    }
  }
  .content1 {
    width: 100%;
    // min-height: 100px;
    // margin-top: 50px;
    color: #fff;
    font-family: "MiSans-regular";
    font-size: 12px;
    font-weight: 400;
    line-height: 15.91px;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    .stepText1 {
      width: 28%;
      position: absolute;
      left: 5%;
      top: 0;
    }
    .stepText2 {
      width: 28%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
    .stepText3 {
      width: 28%;
      position: absolute;
      right: 5%;
      top: 0;
    }
  }
  .createCard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0;
    color: #fff;
    width: 188px;
    height: 38px;
    border-radius: 60px;
    background: linear-gradient(181deg, #846d37 8.32%, #624d21 96.16%);
    font-family: "MiSans-Demibold";
    font-size: 14px;
    font-weight: 500;
    line-height: 18.56px;
  }
}
@keyframes move {
  0% {
    opacity: 0;
    transform: translate(100%, 100%) rotate(45deg); /* 从右下角进入 */
  }
  100% {
    opacity: 1;
    transform: rotate(3.25deg); /* 向左旋转 3.25 度 */
  }
}
</style>
